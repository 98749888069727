import React, { useEffect, useState } from 'react';
import { useLazyQuery, gql, useQuery } from '@apollo/client';
import DOMPurify from 'dompurify';

const SEARCH_ANIME_QUERY = gql`
  query searchAnime($search: String, $status: AnimeStatusString, $kind: AnimeKindString, $limit: Int, $order: OrderEnum) {
    animes(search: $search, status: $status, kind: $kind, limit: $limit, order: $order) {
      id
      russian
      score
      kind
      episodesAired
      poster {
        originalUrl
      }
      genres {
        russian
        kind
      }
      airedOn {
        year
      }
    }
  }
`;

export default function Search() {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchAnime, { data, loading, error }] = useLazyQuery(SEARCH_ANIME_QUERY);
  const [searchResults, setSearchResults] = useState([]);

  const { data: upcomingReleasesData } = useQuery(SEARCH_ANIME_QUERY, {
    variables: { status: 'released', limit: 25 },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (upcomingReleasesData?.animes) {
      setSearchResults(upcomingReleasesData.animes);
    }
  }, [upcomingReleasesData]);

  const handleInputChange = (e) => {
    const inputValue = e.target.value; // Убрали .trim() для сохранения пробелов
    const sanitizedQuery = DOMPurify.sanitize(inputValue);
    setSearchQuery(sanitizedQuery);

    if (sanitizedQuery.length > 3) {
      searchAnime({ variables: { search: sanitizedQuery, limit: 25 } });
    } else {
      setSearchResults(upcomingReleasesData?.animes || []);
    }
  };

  useEffect(() => {
    if (data?.animes) {
      setSearchResults(data.animes);
    }
  }, [data]);

  function renderScoreWithSVG(score) {
    if (score >= 8) {
      return (
        <>
          {score}
        </>
      );
    } else {
      return (
        <>{score}</>
      );
    }
  }

  return (
    <div className="Search">
      <div className="search_input">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.8031 14.4327L21.2727 18.9665L19.0227 21.2727L14.4383 16.7997C13.2821 17.5192 11.947 17.8997 10.5852 17.8977C6.54646 17.8977 3.27271 14.6161 3.27271 10.5852C3.27271 6.54648 6.55433 3.27273 10.5852 3.27273C14.624 3.27273 17.8977 6.55435 17.8977 10.5852C17.8997 11.9448 17.5205 13.2778 16.8031 14.4327ZM5.51933 10.5312C5.51933 13.3212 7.78508 15.5937 10.5818 15.5937C13.3718 15.5937 15.6443 13.3269 15.6443 10.5312C15.6443 7.74123 13.3775 5.46873 10.5818 5.46873C7.79183 5.46873 5.51933 7.73448 5.51933 10.5312Z"
            fill="white"
          />
        </svg>
        <input
          placeholder="Введите название аниме..."
          value={searchQuery}
          onChange={handleInputChange}
        />
      </div>
      <div className="search_result">
        {loading ? (
          <p>Ищем аниме...</p>
        ) : error ? (
          <p>Аниме ненайдено</p>
        ) : searchResults.length > 0 ? (
          searchResults.map((anime) => (
            <a href={`/anime/${anime.id}`} key={anime.id} className='search_element'>
              <div className="search_cart" key={anime.id}>
                <img src={anime.poster?.originalUrl || ''} alt={anime.russian} />
                <div className="search_cart_info">
                  <p className="title_cart_info">{anime.russian}</p>
                  <p className='genres_cart_info'>{anime.genres.filter((genre) => genre.kind === 'genre' || genre.kind === 'demographic')
                          .map((genre) => genre.russian)
                          .join(', ')}</p>
                  <p className="info_cart_info">
                    <div>{anime.episodesAired || '0'} эп</div>
                    <div className="beetween">·</div>
                    <div
                      className={`${
                        anime.score >= 8 ? 'hard_score' : anime.score <= 7 ? 'low_score' : 'high_score'
                      }`}
                    >
                      {renderScoreWithSVG(anime.score.toFixed(1))}
                    </div>
                  </p>
                </div>
              </div>
            </a>
          ))
        ) : (
          <p>Ничего не найдено</p>
        )}
      </div>
    </div>
  );
}
