import React, { useState, useEffect } from 'react';

export default function Banner() {
    const videoData = [
        {
            poster: 'https://dere.shikimori.one/uploads/poster/animes/57524/6f68c06ebf914686319bedd34c89acca.jpeg',
            ids: 57524,
            title_poster: '../media/posters_logo/logo(57524).png',
            desc: 'Большинство школьных пар распадается буквально за год, а к моменту выпуска дотягивают лишь единицы. Пережитые за этот период испытания сплачивают возлюбленных, делая их связь крепче. А что насчёт отвергнутых? Девушек, которые в ответ на признание получили отказ? Таких в современном мире принято называть проигравшими героинями. Кадзухико Нукумидзу, обычному старшекласснику, который любит читать, довелось поближе познакомиться с одной такой. Ею оказалась его одноклассница Анна Янами, которая была влюблена в друга детства. Девушка набралась храбрости и призналась возлюбленному, но симпатия оказалась односторонней. Свидетелем рухнувших надежд девушки как раз стал Кадзухико, и с тех пор Анна не перестаёт засыпать одноклассника жалобами о разбитом сердце. Вскоре выяснилось, помимо Анны, есть ещё две отвергнутые девушки, которые словно гром среди ясного неба свались на голову парня. А не слишком ли много проигравших героинь ворвалось в мирные будни Кадзухико?!',
        },
        {
            poster: 'https://dere.shikimori.one/uploads/poster/animes/41457/2ad3eae1d7c270595de4c21ce5276f38.jpeg',
            ids: 41457,
            title_poster: '../media/posters_logo/logo(41457).png',
            desc: 'Долгое время республику Сан-Магнолию осаждала империя Гиадиан, создавшая серию беспилотных дронов под названием «Легион». После многих лет исследований республика разработала собственное автономное оружие, и теперь может вести войну без потерь — по крайней мере, так утверждает правительство. Однако бескровной войны не бывает. За крепостными стенами, защищающими 85 территорий республики, находится «несуществующий» 86-й сектор. Юноши и девушки этой заброшенной земли управляют «беспилотным» оружием и каждый день гибнут в битвах.',
        },
        // Добавьте другие элементы по мере необходимости
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex(prevIndex => (prevIndex + 1) % videoData.length);
        }, 3600000); // 1 час в миллисекундах

        return () => clearInterval(interval); // Очистка интервала при размонтировании компонента
    }, [videoData.length]);

    return (
        <div className='Banner'>
            <div className='poster_image'>
                <img src={videoData[currentIndex].poster} alt="Poster" />
                <div className='shadow_poster_image'></div>
            </div>
            <div className='info_banner'>
                <div className='logo_poster_banner'>
                    <img src={videoData[currentIndex].title_poster} alt="Title Poster" />
                </div>
                <div className='banner_desc'>{videoData[currentIndex].desc}</div>
                <div className='butt_info_banner'>
                    <div className='watch_butt_info_banner'>
                        <svg width="18" height="18" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_183_51)">
                                <path fillRule="evenodd" clipRule="evenodd" d="M0.9375 0V15L14.0625 7.5L0.9375 0Z" fill="black"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_183_51">
                                    <rect width="15" height="15" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                        Смотреть сериал
                    </div>
                </div>
            </div>
        </div>
    );
}
