import React from 'react';
import { useQuery, gql } from '@apollo/client';

// GraphQL запрос для получения аниме со статусом "анонс"
const GET_ANIME_ANNOUNCEMENTS = gql`
  query GetAnimeAnnouncements {
    animes(kind: "tv,tv_24,tv_13", status: "ongoing,latest", limit: 15) {
      id
      name
      russian
      score
      episodesAired
      poster {
        originalUrl
      }
      genres {
        russian
        kind
      }
      description
    }
  }
`;

export default function Popular() { // Исправлено имя компонента
  const { loading, error, data } = useQuery(GET_ANIME_ANNOUNCEMENTS);

  if (loading) return (
    <div className='list'>
        <div className='header_list'>
        <span>Сейчас популярно</span>
        </div>
        <div className='list_catalog'>
            {Array.from({ length: 24 }, (_, index) => (
                <div className='cart cart_loading' >
                    <div className='cart_image'><div className='loading-bar'></div></div>
                    <div className='cart_info'>
                        <p className='title_cart_info'><div className='loading-bar'></div></p>
                        <p className='info_cart_info'>
                            <div className='loading-bar'></div>
                        </p>
                    </div>
                </div>
            ))}
        </div>
    </div>
  );
  if (error) return (
    <div className='list'>
        <div className='header_list'>
        <span>Сейчас популярно</span>
        </div>
        <div className='list_catalog'>
            {Array.from({ length: 24 }, (_, index) => (
                <div className='cart cart_loading' >
                    <div className='cart_image'><div className='loading-bar'></div></div>
                    <div className='cart_info'>
                        <p className='title_cart_info'><div className='loading-bar'></div></p>
                        <p className='info_cart_info'>
                            <div className='loading-bar'></div>
                        </p>
                    </div>
                </div>
            ))}
        </div>
    </div>
  );

  function renderScoreWithSVG(score) {
    if (score >= 8) {
      return (
        <>
          {score}
        </>
      );
    } else {
      return (
        <>{score}</>
      );
    }
  }

  return (
    <div className='list'>
      <div className='header_list'>
        <span>Сейчас популярно</span>
      </div>
      <div className='list_catalog'>
        {data.animes.map((anime) => (
            <a href={`/anime/${anime.id}`}>
                <div className='cart' key={anime.id}>
                    <img src={anime.poster.originalUrl} alt={anime.russian} />
                    <div className='cart_info'>
                        <p className='title_cart_info'>{anime.russian}</p>
                        <p className='info_cart_info'>
                            <div>{anime.episodesAired || "0"} эп</div>
                            <div className='beetween'>·</div>
                            <div
                            className={`${
                                anime.score >= 8 ? 'hard_score' : anime.score <= 7 ? 'low_score' : 'high_score'
                            }`}
                            >
                                {renderScoreWithSVG(anime.score.toFixed(1))}
                            </div>
                        </p>
                    </div>
                </div>
            </a>
        ))}
      </div>
    </div>
  );
}
