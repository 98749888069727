import React, { Component } from 'react'
import Auth from '../components/auth'

export default class auth extends Component {
  render() {
    return (
      <>
        <Auth />
      </>
    )
  }
}
