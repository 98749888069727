import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";

const TestPlayer = () => {
  const { id } = useParams();
  const location = useLocation();
  const [isEpisodeMenuVisible, setEpisodeMenuVisible] = useState(false);
  const [isTranslationMenuVisible, setTranslationMenuVisible] = useState(false);
  const [selectedTranslation, setSelectedTranslation] = useState(null);
  const [selectedEpisode, setSelectedEpisode] = useState(null);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Toggle the episode menu (close it if it's already visible)
  const toggleEpisodeMenu = () => {
    setEpisodeMenuVisible((prevState) => !prevState);
    setTranslationMenuVisible(false); // Close translation menu if it's open
  };

  // Toggle the translation menu (close it if it's already visible)
  const toggleTranslationMenu = () => {
    setTranslationMenuVisible((prevState) => !prevState);
    setEpisodeMenuVisible(false); // Close episode menu if it's open
  };

  const getEpisodesRange = (lastEpisode, episodesCount) => {
    const startEpisode = lastEpisode - episodesCount + 1;
    return Array.from({ length: episodesCount }, (_, i) => i + startEpisode).map(
      (episode) => `${episode} эпизод`
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = process.env.REACT_APP_KODIK_TOKEN;

      if (!token) {
        console.error("Token is not defined");
        return;
      }

      const url = `https://kodikapi.com/search?token=${token}&shikimori_id=${id}&with_episodes_data=true`;
      try {
        const response = await fetch(url);
        if (!response.ok) {
          console.error("Error fetching data:", response.statusText);
          return;
        }
        const result = await response.json();

        if (result && result.results && result.results.length > 0) {
          setData(result.results);

          const firstTranslation = result.results[0].translation;
          const firstEpisode = getEpisodesRange(
            result.results[0].last_episode,
            result.results[0].episodes_count
          )[0];

          setSelectedTranslation(firstTranslation);
          
          // Define queryParams here
          const queryParams = new URLSearchParams(location.search);
          const episodeParam = queryParams.get("episode");
          if (episodeParam) {
            setSelectedEpisode(episodeParam);
          } else {
            setSelectedEpisode(firstEpisode);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id, location.search]);

  const handleTranslationSelect = (translation) => {
    setSelectedTranslation(translation);
    setEpisodeMenuVisible(true); // Show episode menu
    setTranslationMenuVisible(false); // Close translation menu
  };

  // Получаем ссылку для плеера с учетом выбранной серии и перевода
  const getPlayerLink = () => {
    if (!selectedTranslation || !selectedEpisode || !data || data.length === 0) return ''; // Check if data is defined and not empty
  
    const selectedItem = data.find(item => item.translation.id === selectedTranslation.id);
    if (selectedItem) {
      const episodeNumber = selectedEpisode.split(' ')[0]; // Extract episode number
      const baseLink = selectedItem.link; // Use link from API
      return `${baseLink}?episode=${episodeNumber}&hide_selectors=true`; // Form link with episode parameter
    }
    return ''; // If link not found
  };

  const goToNextEpisode = () => {
    if (!selectedEpisode) return;

    const episodes = getEpisodesRange(data[0].last_episode, data[0].episodes_count);
    const currentIndex = episodes.indexOf(selectedEpisode);
    if (currentIndex < episodes.length - 1) {
      setSelectedEpisode(episodes[currentIndex + 1]);
    }
  };

  const goToPreviousEpisode = () => {
    if (!selectedEpisode) return;

    const episodes = getEpisodesRange(data[0].last_episode, data[0].episodes_count);
    const currentIndex = episodes.indexOf(selectedEpisode);
    if (currentIndex > 0) {
      setSelectedEpisode(episodes[currentIndex - 1]);
    }
  };

  const rewind90Seconds = () => {
    const iframe = document.querySelector('iframe');
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        '{"event":"command","func":"seekTo","args":[90]}',
        '*'
      );
    }
  };

  const episodes = data && data.length > 0 && selectedTranslation
    ? getEpisodesRange(data[0].last_episode, data[0].episodes_count)
    : [];

  const isFirstEpisode = episodes.indexOf(selectedEpisode) === 0;
  const isLastEpisode = episodes.indexOf(selectedEpisode) === episodes.length - 1;

  return (
    <>
      <div style={{ margin: "15px 10px 0px 10px" }}>
        <div className="panel_player">
          <iframe src={getPlayerLink()} frameBorder="0" allowFullScreen allow="autoplay *; fullscreen *"></iframe>
        </div>
      </div>
      <div className="panel_player_info">
        <span>{data && data.length > 0 ? data[0].title : ""}</span>
        <p>{selectedTranslation ? selectedTranslation.title : ""}</p>
        <p>{selectedEpisode ? selectedEpisode : ""} эпизод</p>
      </div>
      <div className="panel_player_butt">
        <a href={`/anime/${id}`}>
          <div className="player_butt">Назад</div>
        </a>

        {!isFirstEpisode && (
          <div className="player_butt" onClick={goToPreviousEpisode}>
            Предыдущий эпизод
          </div>
        )}
        {!isLastEpisode && (
          <div className="player_butt" onClick={goToNextEpisode}>
            Следующий эпизод
          </div>
        )}
      </div>

      <div className={`page_selection_menu ${isTranslationMenuVisible ? "visible" : "hidden"}`}>
        <div className="nav_bar_page_selection_menu">
            <span>Выберите озвучку:</span>
        </div>
        <div className="select_block">
            {data && data.length > 0 ? (
            data.map((item, index) => (
                <div 
                className={`select_block_el ${selectedTranslation && selectedTranslation.id === item.translation.id ? 'active' : ''}`} 
                key={index} 
                onClick={() => handleTranslationSelect(item.translation)}
                >
                <span>{item.translation.title}</span>
                </div>
            ))
            ) : (
            <></>
            )}
            <div style={{ height: '71px' }}></div>
        </div>
      </div>

      <div className={`page_selection_menu ${isEpisodeMenuVisible ? "visible" : "hidden"}`}>
        <div className="nav_bar_page_selection_menu">
            <span>Выберите эпизод:</span>
        </div>
        <div className="select_block_episode">
            {data && data.length > 0 && selectedTranslation ? (
            data
                .filter((item) => item.translation.id === selectedTranslation.id)
                .map((item, index) => {
                const episodes = getEpisodesRange(item.last_episode, item.episodes_count);
                const lastSeason = item.last_season;

                // Получаем номер текущего эпизода
                const currentEpisodeNumber = selectedEpisode ? parseInt(selectedEpisode.split(' ')[0]) : 0;

                return episodes
                    .filter(episode => {
                    const episodeNumber = parseInt(episode.split(' ')[0]); // Извлекаем номер эпизода
                    return episodeNumber >= currentEpisodeNumber; // Показываем только эпизоды >= текущего
                    })
                    .map((episode, i) => {
                    // Находим скриншоты для текущего эпизода
                    const episodeIndex = episode.split(' ')[0]; // Извлекаем номер эпизода
                    const episodeScreenshots = item.seasons[lastSeason]?.episodes[episodeIndex]?.screenshots || [];

                    return (
                        <div className='item_episode_el' key={`${index}-${i}`} onClick={() => {
                        setSelectedEpisode(episode);
                        setEpisodeMenuVisible(false);
                        window.scrollTo({ top: 0, behavior: 'smooth' }); // Прокрутка страницы вверх
                        }}>
                        {episodeScreenshots.length > 0 ? (
                            <img src={episodeScreenshots[0]} alt={`Скриншот эпизода ${index + 1}`} />
                        ) : (
                            <img src="../icons/item.jpg" alt="Эпизод" />
                        )}
                        <div className='info_item_episode'>
                            <div className='info_item_episode_main'>
                            <div className='episode_item_name_circle'></div>
                            {episode}
                            </div>
                            <div className='info_item_episode_desc'>Нет описание к серии</div>
                        </div>
                        </div>
                    );
                    });
                })
            ) : (
            <></>
            )}
            <div style={{ height: '71px' }}></div>
        </div>
      </div>


    </>
  );
};

export default TestPlayer;
