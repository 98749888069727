import React, { useState } from "react";
import Welcome from "./welcome";
import Search from "./search";
import Profile from "./profile";
import NavBar from '../components/header'
import Collection from "./collection";

export default function Page() {
  const [activePage, setActivePage] = useState("home_page");
  const [transitioning, setTransitioning] = useState(false);

  const handleNavClick = (page) => {
    if (page === activePage) return;

    // Начинаем анимацию перехода
    setTransitioning(true);
    setTimeout(() => {
      setActivePage(page);
      setTransitioning(false);
      window.scrollTo(0, 0); // Устанавливаем прокрутку в верхнюю часть страницы
    }, 300); // Время анимации совпадает с CSS
  };

  return (
    <div className="page">
      <NavBar />
      {/* Pages */}
      <div
        className={`page_content ${
          activePage === "home_page" ? "visible" : ""
        } ${transitioning ? "fade-out" : ""}`}
      >
        <Welcome />
      </div>
      <div
        className={`page_content ${
          activePage === "collection_page" ? "visible" : ""
        } ${transitioning ? "fade-out" : ""}`}
      >
        <Collection />
      </div>
      <div
        className={`page_content ${
          activePage === "search_page" ? "visible" : ""
        } ${transitioning ? "fade-out" : ""}`}
      >
        <Search />
      </div>
      <div
        className={`page_content ${
          activePage === "profile_page" ? "visible" : ""
        } ${transitioning ? "fade-out" : ""}`}
      >
        <Profile />
      </div>

      {/* Navbar */}
      <div className="NavBar">
        <div
          className={`to ${activePage === "home_page" ? "active_to" : ""}`}
          onClick={() => handleNavClick("home_page")}
        >
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11 2.75L2.75 9.35V19.25H9.16665V13.75H12.8333V19.25H19.25V9.35L11 2.75Z"
              fill="#fff"
            />
          </svg>
          Главная
        </div>
        <div
          className={`to ${activePage === "collection_page" ? "active_to" : ""}`}
          onClick={() => handleNavClick("collection_page")}
        >
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.5 2.75V19.25L11 15.5833L5.5 19.25V2.75H16.5Z"
              fill="white"
            />
          </svg>
          Коллекция
        </div>
        <div
          className={`to ${activePage === "search_page" ? "active_to" : ""}`}
          onClick={() => handleNavClick("search_page")}
        >
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.4028 13.23L19.5 17.3859L17.4375 19.5L13.2352 15.3997C12.1753 16.0593 10.9515 16.408 9.70312 16.4062C6.00094 16.4062 3 13.3981 3 9.70312C3 6.00094 6.00816 3 9.70312 3C13.4053 3 16.4062 6.00816 16.4062 9.70312C16.4081 10.9494 16.0605 12.1713 15.4028 13.23ZM5.05941 9.65363C5.05941 12.2111 7.13634 14.2942 9.70003 14.2942C12.2575 14.2942 14.3407 12.2163 14.3407 9.65363C14.3407 7.09613 12.2627 5.013 9.70003 5.013C7.14253 5.013 5.05941 7.08994 5.05941 9.65363Z"
              fill="white"
            />
          </svg>
          Поиск
        </div>
        <div
          className={`to ${activePage === "profile_page" ? "active_to" : ""}`}
          onClick={() => handleNavClick("profile_page")}
        >
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.25 3C13.438 3 15.5365 3.86919 17.0836 5.41637C18.6308 6.96354 19.5 9.06196 19.5 11.25C19.5 15.8063 15.8063 19.5 11.25 19.5C6.69365 19.5 3 15.8063 3 11.25C3 6.69365 6.69365 3 11.25 3ZM12.075 12.075H10.425C8.38256 12.075 6.62903 13.312 5.87275 15.0778C7.06941 16.7558 9.03193 17.85 11.25 17.85C13.4681 17.85 15.4306 16.7558 16.6273 15.0777C15.871 13.312 14.1174 12.075 12.075 12.075ZM11.25 5.475C9.8831 5.475 8.775 6.5831 8.775 7.95C8.775 9.31691 9.8831 10.425 11.25 10.425C12.6169 10.425 13.725 9.31691 13.725 7.95C13.725 6.5831 12.6169 5.475 11.25 5.475Z"
              fill="white"
            />
          </svg>
          Профиль
        </div>
      </div>
    </div>
  );
}
