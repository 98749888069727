import React, { Component } from 'react'
import Search from '../components/search'

export default class search extends Component {
  render() {
    return (
      <>
       <Search />
      </>
    )
  }
}
