import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useLazyQuery, gql } from '@apollo/client';
import axios from 'axios';

// GraphQL query to get anime announcements
const GET_ANIME_ANNOUNCEMENTS = gql`
  query GetAnimeAnnouncements($id: String!) {
    animes(ids: $id) {
      id
      russian
      score
      episodesAired
      poster {
        originalUrl
      }
      genres {
        russian
        kind
      }
      description
    }
  }
`;

export default function Collection( {animeId} ) {
    const { userId } = useParams();
    const [animeIds, setAnimeIds] = useState([]);
    const [viewedAnimeIds, setViewedAnimeIds] = useState([]);
    const [activeTab, setActiveTab] = useState('remembered');
    const [animes, setAnimes] = useState([]);
    const [viewedAnimes, setViewedAnimes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate(); // Хук для навигации

    const [fetchAnime] = useLazyQuery(GET_ANIME_ANNOUNCEMENTS, {
        onCompleted: (data) => {
            if (data && data.animes) {
                if (activeTab === 'remembered') {
                    setAnimes(prev => [...prev, ...data.animes]);
                } else if (activeTab === 'viewed') {
                    setViewedAnimes(prev => [...prev, ...data.animes]);
                }
            }
            setLoading(false); // Устанавливаем loading в false после завершения загрузки
        },
        onError: (err) => {
            setError(err);
            console.error('GraphQL Error:', err);
            setLoading(false); // Устанавливаем loading в false при ошибке
        }
    });

    const fetchUser  = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            // Если токен отсутствует, перенаправляем на страницу авторизации
            navigate('/auth');
            return; // Выходим из функции
        }
        
        try {
            const response = await axios.get('https://api.aniplay.fun/api/collection', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const collection = response.data;

            // Разделяем аниме по типу коллекции
            const rememberedIds = collection
                .filter(item => item.collection_type === 'remembered')
                .map(item => item.anime_id);
            const viewedIds = collection
                .filter(item => item.collection_type === 'viewed')
                .map(item => item.anime_id);

            // Убираем дубликаты
            const uniqueRememberedIds = [...new Set(rememberedIds)];
            const uniqueViewedIds = [...new Set(viewedIds)];

            setAnimeIds(uniqueRememberedIds);
            setViewedAnimeIds(uniqueViewedIds);
        } catch (error) {
            console.error('Error fetching user collection:', error);
            setError(error);
        }
    };

    useEffect(() => {
        fetchUser ();
    }, [userId]);

    const fetchAllAnimes = async (ids) => {
        setLoading(true);
        setError(null);
        for (let i = 0; i < ids.length; i += 2) {
            const chunk = ids.slice(i, i + 2).join(',');
            await fetchAnime({ variables: { id: chunk } });
        }
    };

    useEffect(() => {
        if (activeTab === 'remembered' && animeIds.length > 0 && animes.length === 0) {
            fetchAllAnimes(animeIds);
        } else if (activeTab === 'viewed' && viewedAnimeIds.length > 0 && viewedAnimes.length === 0) {
            fetchAllAnimes(viewedAnimeIds);
        }
    }, [activeTab, animeIds, viewedAnimeIds]);

    const handleTabClick = (tab) => {
        if (tab !== activeTab) {
            setActiveTab(tab);
            // Загрузка аниме только если они еще не загружены
            if (tab === 'remembered' && animes.length === 0) {
                fetchAllAnimes(animeIds);
            } else if (tab === 'viewed' && viewedAnimes.length === 0) {
                fetchAllAnimes(viewedAnimeIds);
            }
        }
    };

    const handleRemoveFromCollection = async (animeId, type) => {
        const token = localStorage.getItem('token');
    
        if (!token) {
            navigate('/auth'); // Redirect to authentication page if token is absent
            return; // Exit the function
        }
    
        try {
            const response = await axios.delete(`https://api.aniplay.fun/api/collection/${animeId}/${type}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
    
            console.log('Response from server:', response.data);
            
            // Update state after successful deletion
            if (type === 'remembered') {
                setAnimes(prev => prev.filter(anime => anime.id !== animeId));
            } else if (type === 'viewed') {
                setViewedAnimes(prev => prev.filter(anime => anime.id !== animeId));
            }
        } catch (error) {
            console.error('Ошибка при удалении из коллекции:', error.response ? error.response.data : error);
        }
    };
    
    
    
    
    
    function renderScoreWithSVG(score) {
        if (score >= 8) {
            return (
                <>
                    {score}
                </>
            );
        } else {
            return (
                <>{score}</>
            );
        }
    }

    if (loading) {
        return (
            <div className='collection_header'>
                <div className='link_collection_tablist'>
                    <div
                        className={`ul_link_collection_tablist ${activeTab === 'remembered' ? 'link_active' : ''}`}
                        onClick={() => handleTabClick('remembered')}
                    >
                        <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.0566 2.75V19.25L12.0377 15.5833L6.01886 19.25V2.75H18.0566Z" fill="#fff"/>
                        </svg>
                        Запомненные
                    </div>
                    <div
                        className={`ul_link_collection_tablist ${activeTab === 'viewed' ? 'link_active' : ''}`}
                        onClick={() => handleTabClick('viewed')}
                    >
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11 3.66666C17.4167 3.66666 20.1667 11 20.1667 11C20.1667 11 17.4167 18.3333 11 18.3333C4.58337 18.3333 1.83337 11 1.83337 11C1.83337 11 4.58337 3.66666 11 3.66666ZM11 7.79166C9.22814 7.79166 7.79171 9.2281 7.79171 11C7.79171 12.7719 9.22814 14.2083 11 14.2083C12.7719 14.2083 14.2084 12.7719 14.2084 11C14.2084 9.2281 12.7719 7.79166 11 7.79166ZM11 12.375C10.2419 12.375 9.62504 11.7582 9.62504 11C9.62504 10.2418 10.2419 9.625 11 9.625C11.7582 9.625 12.375 10.2418 12.375 11C12.375 11.7582 11.7582 12.375 11 12.375Z" fill="white"/>
                        </svg>
                        Просмотренные
                    </div>
                </div>
                {activeTab === 'remembered' && (
                    <div className='tab_open_list_remembered'>
                        {Array.from({ length: 4 }, (_, index) => (
                            <div className='cart' >
                                <div className='cart_image'><div className='loading-bar'></div></div>
                                <div className='cart_info'>
                                    <p className='title_cart_info'><div className='loading-bar'></div></p>
                                    <p className='info_cart_info'>
                                        <div className='loading-bar'></div>
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                {activeTab === 'viewed' && (
                    <div className='tab_open_list_viewed'>
                        {Array.from({ length: 4 }, (_, index) => (
                            <div className='cart' >
                                <div className='cart_image'><div className='loading-bar'></div></div>
                                <div className='cart_info'>
                                    <p className='title_cart_info'><div className='loading-bar'></div></p>
                                    <p className='info_cart_info'>
                                        <div className='loading-bar'></div>
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    }

    if (error) {
        return (
            <div className="kp-loader">
                <div className="kp-loader-circle"></div>
            </div>
        );
    }

    return (
        <div className='collection_header'>
            <div className='link_collection_tablist'>
                <div
                    className={`ul_link_collection_tablist ${activeTab === 'remembered' ? 'link_active' : ''}`}
                    onClick={() => handleTabClick('remembered')}
                >
                    <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.0566 2.75V19.25L12.0377 15.5833L6.01886 19.25V2.75H18.0566Z" fill="#fff"/>
                    </svg>
                    Запомненные
                </div>
                <div
                    className={`ul_link_collection_tablist ${activeTab === 'viewed' ? 'link_active' : ''}`}
                    onClick={() => handleTabClick('viewed')}
                >
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11 3.66666C17.4167 3.66666 20.1667 11 20.1667 11C20.1667 11 17.4167 18.3333 11 18.3333C4.58337 18.3333 1.83337 11 1.83337 11C1.83337 11 4.58337 3.66666 11 3.66666ZM11 7.79166C9.22814 7.79166 7.79171 9.2281 7.79171 11C7.79171 12.7719 9.22814 14.2083 11 14.2083C12.7719 14.2083 14.2084 12.7719 14.2084 11C14.2084 9.2281 12.7719 7.79166 11 7.79166ZM11 12.375C10.2419 12.375 9.62504 11.7582 9.62504 11C9.62504 10.2418 10.2419 9.625 11 9.625C11.7582 9.625 12.375 10.2418 12.375 11C12.375 11.7582 11.7582 12.375 11 12.375Z" fill="white"/>
                    </svg>
                    Просмотренные
                </div>
            </div>
            {activeTab === 'remembered' && (
                <div className='tab_open_list_remembered'>
                    {animes.length === 0 ? (
                        <p>Нет аниме в коллекции.</p>
                    ) : (
                        animes.map((anime) => (
                            <a href={`/anime/${anime.id}`} key={anime.id} onClick={(e) => {
                                if (e.target.closest('.episode_item_close_button')) {
                                    e.preventDefault();
                                }
                            }}>
                                <div className='cart' key={anime.id}>
                                    <img src={anime.poster.originalUrl} alt={anime.russian} />
                                    <div className='cart_info'>
                                        <p className='title_cart_info'>{anime.russian}</p>
                                        <p className='info_cart_info'>
                                            <div>{anime.episodesAired || "0"} эп</div>
                                            <div className='beetween'>·</div>
                                            <div
                                            className={`${
                                                anime.score >= 8 ? 'hard_score' : anime.score <= 7 ? 'low_score' : 'high_score'
                                            }`}
                                            >
                                                {renderScoreWithSVG(anime.score.toFixed(1))}
                                            </div>
                                        </p>
                                    </div>
                                </div>
                            </a>
                        ))
                    )}
                </div>
            )}
            {activeTab === 'viewed' && (
                <div className='tab_open_list_viewed'>
                    {viewedAnimes.length === 0 ? (
                        <p>Нет просмотренных аниме.</p>
                    ) : (
                        viewedAnimes.map((anime) => (
                            <div key={anime.id} className='item_open_list'>
                                <a href={`/anime/${anime.id}`} key={anime.id} onClick={(e) => {
                                if (e.target.closest('.episode_item_close_button')) {
                                    e.preventDefault();
                                }
                                }}>
                                    <div className='cart' key={anime.id}>
                                        <img src={anime.poster.originalUrl} alt={anime.russian} />
                                        <div className='cart_info'>
                                            <p className='title_cart_info'>{anime.russian}</p>
                                            <p className='info_cart_info'>
                                                <div>{anime.episodesAired || "0"} эп</div>
                                                <div className='beetween'>·</div>
                                                <div
                                                className={`${
                                                    anime.score >= 8 ? 'hard_score' : anime.score <= 7 ? 'low_score' : 'high_score'
                                                }`}
                                                >
                                                    {renderScoreWithSVG(anime.score.toFixed(1))}
                                                </div>
                                            </p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        ))
                    )}
                </div>
            )}
        </div>
    );
}
