import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';

const statusTranslations = {
  released: 'Вышел',
  ongoing: 'Онгоинг',
  latest: 'Последний',
  anons: 'Анонсирован'
};

const kindTranslationsInfo = {
  tv: 'сериале',
  movie: 'фильме',
  ova: 'OVA',
  ona: 'ONA',
  special: 'спецвыпуске',
  tv_special: 'TV Спецвыпуске',
};

const kindTranslations = {
  tv: 'TV Сериал',
  movie: 'Фильм',
  ova: 'OVA',
  ona: 'ONA',
  special: 'Спецвыпуск',
  tv_special: 'TV Спецвыпуск',
};

export default function PlayEpisode({ anime, userId  }) {
  const episodeListRef = useRef(null);
  const episodeListRef2 = useRef(null);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [viewerImageUrl, setViewerImageUrl] = useState('');
  const [openingUrl, setOpeningUrl] = useState(); // Добавлено состояние
  const [isFetchedFromServer, setIsFetchedFromServer] = useState(false); // Новое состояние
  const [kinopoiskUrl, setKinopoiskUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const animeId = anime.id;
  const [materialData, setMaterialData] = useState(null);

  const [selectedAnime, setSelectedAnime] = useState(null);
  
  const [apiData, setApiData] = useState(null); // Новое состояние для данных API
  const [hasError, setHasError] = useState(false); // Состояние для проверки ошибок

  const [showVideo, setShowVideo] = useState(false);
  const videoRef = useRef(null);
  const imageRef = useRef(null);

  const [isMuted, setIsMuted] = useState(true);
  const [volume, setVolume] = useState(100); // Начальный уровень громкости

  const [newComment, setNewComment] = useState('');
  const [comments, setComments] = useState([]);

  const [isRemembered, setIsRemembered] = useState(false);
  const [isViewed, setIsViewed] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [isVisible, setIsVisible] = useState(false);

  const showMessage = (messageType, message) => {
    if (messageType === 'error') setErrorMessage(message);
    else setSuccessMessage(message);
    setIsVisible(true);
    setTimeout(() => setIsVisible(false), 5000);
  };

  const handleEpisodeClick = (episodeIndex) => {
    console.log('Selected Anime:', selectedAnime); // Debugging line
    if (selectedAnime) {
      // Navigate to the episode only if selectedAnime is not null
      window.location.href = `/play/${selectedAnime.id}?episode=${episodeIndex + 1}`;
    } else {
      console.error('Selected anime is null or undefined');
    }
  };

  const checkAnimeInCollection = async () => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.get(`https://api.aniplay.fun/api/collection`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        const collection = response.data;

        console.log("Collection data:", collection); // Отладочная информация
        console.log("Checking for anime id:", anime.id); // Отладочная информация

        const remembered = collection.some(item => Number(item.anime_id) === Number(anime.id) && item.collection_type === 'remembered');
        const viewed = collection.some(item => Number(item.anime_id) === Number(anime.id) && item.collection_type === 'viewed');

        console.log("Remembered:", remembered); // Отладочная информация
        console.log("Viewed:", viewed); // Отладочная информация

        setIsRemembered(remembered);
        setIsViewed(viewed);
    } catch (error) {
        console.error('Ошибка при получении коллекции:', error);
        setErrorMessage('Ошибка при получении коллекции.');
    }
};

  const handleAddToRemembered = async () => {
    if (isRemembered) {
        showMessage('error', 'Аниме уже добавлено в "Запомненные"!');
        return;
    }
    try {
        const token = localStorage.getItem('token');
        await axios.post('https://api.aniplay.fun/api/collection', {
            user_id: userId,
            anime_id: anime.id,
            collection_type: 'remembered'
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        setIsRemembered(true);
        showMessage('success', 'Аниме успешно добавлено в "Запомненные"!');
    } catch (error) {
        showMessage('error', 'Ошибка при добавлении аниме в "Запомненные".');
    }
  };

  const handleAddToViewed = async () => {
    if (isViewed) {
        showMessage('error', 'Аниме уже добавлено в "Просмотренные"!');
        return;
    }
    try {
        const token = localStorage.getItem('token');
        await axios.post('https://api.aniplay.fun/api/collection', {
            user_id: userId,
            anime_id: anime.id,
            collection_type: 'viewed'
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        setIsViewed(true);
        showMessage('success', 'Аниме успешно добавлено в "Просмотренные"!');
    } catch (error) {
        showMessage('error', 'Ошибка при добавлении аниме в "Просмотренные".');
    }
  };

  useEffect(() => {
    checkAnimeInCollection();
  }, [userId, anime.id]); // Проверяем коллекцию при изменении userId или anime.id


    // Функция для получения комментариев
    const fetchComments = async (animeId) => {
      try {
        const response = await axios.get(`https://api.aniplay.fun/api/comments/${animeId}`);
        return response.data; // Предполагается, что response.data содержит массив комментариев
      } catch (error) {
        console.error('Ошибка получении комментариев:', error);
        throw error;
      }
    };
  
    useEffect(() => {
      const fetchCommentsData = async () => {
        try {
          const commentsData = await fetchComments(anime.id);
          setComments(commentsData);
        } catch (error) {
          console.error('Ошибка при загрузке комментариев:', error);
        }
      };
  
      fetchCommentsData();
    }, [anime.id]);
  
    const handleCommentSubmit = async (e) => {
      e.preventDefault();
      if (!newComment) return;
    
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post('https://api.aniplay.fun/api/comments', {
          anime_id: anime.id,
          comment: newComment
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
    
        // Создаем новый комментарий на основе ответа сервера
        const newCommentData = {
          id: response.data.id, // Предполагается, что сервер возвращает ID нового комментария
          avatar: response.data.avatar, // Предполагается, что сервер возвращает URL аватара
          login: response.data.login, // Предполагается, что сервер возвращает логин пользователя
          comment: newComment
        };
    
        // Обновляем состояние комментариев
        setComments((prevComments) => [...prevComments, newCommentData]);
        setNewComment('');
    
        // Выводим сообщение об успешном добавлении комментария
        showMessage('success', 'Комментарий успешно добавлен!');
    
      } catch (error) {
        console.error('Error adding comment:', error);
        showMessage('error', 'Ошибка при добавлении комментария.');
      }
    };
    

  const handleSoundToggle = () => {
    setIsMuted(prev => !prev);
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted; // Переключаем mute на видео
    }
  };

  useEffect(() => {
    const fetchApiData = async () => {
      setLoading(true); // Начинаем загрузку
      try {
        const response = await fetch(`https://api.aniplay.fun/storage/${anime.id}/`);
        if (response.ok) {
          const data = await response.json();
          setApiData(data);
          setHasError(false);
        } else {
          console.warn(`Anime with id ${anime.id} not found on server.`);
          setHasError(true);
        }
      } catch (error) {
        console.error('Error fetching API data:', error);
        setHasError(true);
      } finally {
        setLoading(false); // Завершаем загрузку
      }
    };

    fetchApiData();
  }, [anime.id]);

  const checkForVideo = () => {
    if (apiData && apiData.trailers) {
      setShowVideo(true);
    } else {
      setShowVideo(false);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      checkForVideo();
    }, 5000);

    return () => clearTimeout(timer);
  }, [apiData]);

  const animateTransition = () => {
    if (imageRef.current && videoRef.current) {
      if (showVideo) {
        imageRef.current.style.transition = 'opacity 0.5s ease';
        imageRef.current.style.opacity = 0;

        setTimeout(() => {
          videoRef.current.style.transition = 'opacity 0.5s ease';
          videoRef.current.style.opacity = 1;
          videoRef.current.play(); // Начинаем воспроизведение сразу
        }, 500);
      } else {
        videoRef.current.style.transition = 'opacity 0.5s ease';
        videoRef.current.style.opacity = 0;

        setTimeout(() => {
          imageRef.current.style.transition = 'opacity 0.5s ease';
          imageRef.current.style.opacity = 1;
        }, 500);
      }
    }
  };

  useEffect(() => {
    animateTransition();
  }, [showVideo]);

  useEffect(() => {
    animateTransition();
  }, [showVideo]);



  // Handle the external links dynamically
  const handleExternalLinkClick = (url) => {
    window.open(url, '_blank');
  };

  useEffect(() => {
    document.title = `AniPlay | ${anime.russian}`;
    if (anime?.externalLinks) {
      // Фильтрация ссылок, чтобы оставить только те, где kind === 'kinopoisk'
      const kinopoiskLink = anime.externalLinks.find(link => link.kind === 'kinopoisk');
      if (kinopoiskLink) {
        setKinopoiskUrl(kinopoiskLink.url); // Сохранить URL, если ссылка найдена
      }
    }
  }, [anime]);

  // Функции прокрутки (остаются без изменений)
  const scrollLeft = () => {
    if (episodeListRef.current) {
      episodeListRef.current.scrollBy({ left: -300, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (episodeListRef.current) {
      episodeListRef.current.scrollBy({ left: 300, behavior: 'smooth' });
    }
  };

  const scrollLeft2 = () => {
    if (episodeListRef2.current) {
      episodeListRef2.current.scrollBy({ left: -300, behavior: 'smooth' });
    }
  };

  const scrollRight2 = () => {
    if (episodeListRef2.current) {
      episodeListRef2.current.scrollBy({ left: 300, behavior: 'smooth' });
    }
  };

  const closeViewer = (e) => {
    if (
      e.target.className === 'photo_viewer_container' || 
      e.target.className === 'anime_body'
    ) {
      setIsViewerOpen(false);
    }
  };

  const openViewer = (imageUrl) => {
    setViewerImageUrl(imageUrl);
    setIsViewerOpen(true);
  };

  const isHentai = anime.genres.some((genre) => genre.russian.toLowerCase() === 'хентай');

  // Функция для форматирования даты
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    
    // Получаем текущую дату
    const currentDate = new Date();
    
    // Форматируем дату как день:месяц:год
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
    const year = date.getFullYear();
    
    // Рассчитываем количество дней до следующей серии
    const timeDifference = date - currentDate;
    const daysLeft = Math.ceil(timeDifference / (1000 * 3600 * 24)); // Переводим разницу в дни

    // Определяем правильное окончание для слова "день"
    const dayWord = daysLeft === 1 ? 'день' : (daysLeft > 1 && daysLeft < 5) ? 'дня' : 'дней';

    // Формируем строку с датой и количеством дней
    return `(через ${daysLeft} ${dayWord})`;
  };

  // useEffect для получения трейлера с сервера
  useEffect(() => {
    const fetchOpening = async () => {
        try {
            const response = await fetch(`https://api.aniplay.fun/anime/${anime.id}`);
            if (response.ok) {
                const data = await response.json();
                if (data.opening) {
                    setOpeningUrl(`https://api.aniplay.fun${data.opening}`);
                    setIsFetchedFromServer(true);
                } else {
                    console.warn(`No opening found for anime with id ${anime.id}.`);
                }
            } else {
                console.error(`Anime with id ${anime.id} not found on server.`);
                setMaterialData(null); // Set materialData to null if not found
            }
        } catch (error) {
            console.error('Error fetching opening URL:', error);
        }
    };

    fetchOpening();
  }, [anime.id]);


  // Найти видео с kind 'op' и извлечь imageUrl
  const opVideo = anime.videos.find(video => video.kind === 'op');
  const opVideoImageUrl = opVideo ? `https:${opVideo.imageUrl}` : '';
  const opVideoName = opVideo ? opVideo.name : 'Название не найдено';
  const opVideoUrl = opVideo ? opVideo.url : '';
  const ratingScore10 = anime.scoresStats.find(stat => stat.score === 10);
  const ratingCount10 = ratingScore10 ? ratingScore10.count : '0';

  const [logo, setLogo] = useState(null);
  const [episodes, setEpisodes] = useState([]);
  const [loadingEpisodes, setLoadingEpisodes] = useState(false);

  const fetchLogo = async (animeId) => {
    try {
      const logoResponse = await fetch(`https://api.aniplay.fun/storage/${animeId}/`);
      const logoData = await logoResponse.json();
      
      return logoData; 
    } catch (error) {
      console.error('Ошибка при получении логотипа:', error);
      return null; 
    }
  };


  const fetchEpisodes = async (animeId) => {
    setLoadingEpisodes(true);
    const token = process.env.REACT_APP_KODIK_TOKEN;

    const logoPromise = fetchLogo(animeId);
    const url = `https://kodikapi.com/search?token=${token}&shikimori_id=${animeId}&with_episodes_data=true&with_material_data=true`;

    console.log("Fetching episodes for animeId:", animeId); // Log animeId
    console.log("API URL:", url); // Log the API URL

    try {
      const [logoData, response] = await Promise.all([logoPromise, fetch(url)]);

      if (!response.ok) {
        throw new Error('Ошибка при загрузке серий');
      }

      const result = await response.json();
      console.log("API Result:", result); // Log the full result

      if (result && result.results && result.results.length > 0) {
        const animeData = result.results[0];
        console.log("Material Data:", animeData.material_data); // Log material_data

        setMaterialData(animeData.material_data);
        const logoUrl = logoData ? logoData.logo : null; 
        setLogo(logoUrl);

        if (animeData && animeData.seasons) {
          const episodesArray = Object.values(animeData.seasons).flatMap(season => {
            return Object.values(season.episodes || {}).map((episode, index) => ({
              id: episode.id,
              title: episode.title || `${index + 1}. Эпизод`,
              screenshots: episode.screenshots || []
            }));
          });
          setEpisodes(episodesArray);
        } else {
          setEpisodes([]);
        }
      } else {
        console.warn("No results found for animeId:", animeId); // Warn if no results
        setEpisodes([]);
      }
    } catch (error) {
      console.error('Ошибка при получении серий:', error);
      setEpisodes([]);
    } finally {
      setLoadingEpisodes(false);
    }
  };

  useEffect(() => {
    // Assuming you have the anime data in your API response
    setSelectedAnime(anime); // Set the selected anime
    fetchEpisodes(animeId);
  }, [animeId]);

  const [isDetailsVisible, setIsDetailsVisible] = useState(false); // State for details visibility
  const [isEpisodeVisible, setIsEpisodeVisible] = useState(false); // State for episode visibility

  const toggleDetails = () => {
    setIsDetailsVisible(prev => !prev); // Toggle details visibility
    window.scrollTo(0, 0); // Прокрутка к верхней части страницы
  };

  const toggleEpisodeVisibility = () => {
      setIsEpisodeVisible(prev => !prev); // Toggle visibility
      window.scrollTo(0, 0); // Прокрутка к верхней части страницы
  };

  const closeEpisode = () => {
    setIsEpisodeVisible(false); // Close the episode block
  }

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const cleanDescription = (description) => {
    return description ? description.replace(/\[.*?\]/g, '') : 'Нет описания';
  };
  

  function renderScoreWithSVG(score) {
    if (score >= 8) {
      return (
        <>
          {score}
        </>
      );
    } else {
      return (
        <>{score}</>
      );
    }
  }

  return (
    <>
        {isVisible && (errorMessage || successMessage) && (
          <div className={`alert ${errorMessage ? 'error' : 'success'}`}>
            <span className="alert_message success">{errorMessage || successMessage}</span>
          </div>
        )}

        {isDetailsVisible ? (
          <div className='page_details_anime visible'>
            <div className='anime_butt_back' onClick={toggleDetails}>
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.9916 16.3109L14.3193 14.9832L10.3361 11L14.3193 7.0168L12.9916 5.68906L7.68066 11L12.9916 16.3109Z" fill="white"/>
                </svg>
                Вернуться к аниме
            </div>
            <div className='main_page_details_anime'>
                <div className='main_page_details_anime_el'>
                    <span>Название</span>
                    <p>{anime.russian}</p>
                </div>
                <div className='main_page_details_anime_el'>
                    <span>Оригинальное название</span>
                    <p>{anime.name}</p>
                </div>
                <div className='main_page_details_anime_el'>
                    <span>Год производства</span>
                    <p>{anime.airedOn ? anime.airedOn.year : 'Не указано'}</p>
                </div>
                <div className='main_page_details_anime_el'>
                    <span>Страна</span>
                    <p>{materialData && materialData.countries 
                      ? (materialData.countries) 
                      : 'Нет информации'
                    }</p>
                </div>
                <div className='main_page_details_anime_el'>
                    <span>Тип</span>
                    <p>{kindTranslations[anime.kind] || anime.kind}</p>
                </div>
                <div className='main_page_details_anime_el'>
                    <span>Студия</span>
                    <p>{anime.studios.length > 0 ? anime.studios[0].name : 'Не указано'}</p>
                </div>
                <div className='main_page_details_anime_el'>
                    <span>Длительность эпизода</span>
                    <p>{anime.duration} мин</p>
                </div>
                <div className='main_page_details_anime_el'>
                    <span>Статус</span>
                    <p>{statusTranslations[anime.status] || anime.status}</p>
                </div>
                <div className='main_page_details_anime_el'>
                    <span>Жанры</span>
                    <p>{anime.genres
                    .filter((genre) => genre.kind === 'genre' || genre.kind === 'demographic')
                    .map((genre) => genre.russian)
                    .join(', ')}</p>
                </div>
                <div className='main_page_details_anime_el'>
                    <span>Описание</span>
                    <p>{materialData && materialData.description 
                      ? (materialData.description) 
                      : 'Нет описания' // Fallback message
                    }</p>
                </div>
            </div>
          </div>
        ) : (
          <div className='page_main_anime'>

            <a href='/app' className='anime_butt_back'>
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.9916 16.3109L14.3193 14.9832L10.3361 11L14.3193 7.0168L12.9916 5.68906L7.68066 11L12.9916 16.3109Z" fill="white"/>
                </svg>
                Назад
            </a>

            <div className='iframe_anime_body'>
                <div className='back_image_poster'>
                  <video
                    ref={videoRef}
                    style={{ opacity: showVideo ? 1 : 0 }}
                    onEnded={() => setShowVideo(false)}
                    muted={isMuted} // Устанавливаем начальное состояние звука
                    src={apiData ? apiData.trailers : ''}
                    preload="auto"
                    controls={false}
                    playsInline
                  />
                  <img
                    ref={imageRef}
                    src={anime.poster.originalUrl}
                    alt="Anime Image"
                    style={{ opacity: showVideo ? 0 : 1 }}
                  />
                  <div className="overlay_background_poster_anime"></div>
                </div>

                <div className='cont_info_iframe_anime_body'>
                    {apiData ? (
                      <div className='cont_info_iframe_anime_body_logo'>
                        <img src={apiData.logo} />
                      </div>
                    ) : hasError ? (
                      <div className='cont_info_iframe_anime_body_title_ru'>{anime.russian}</div>
                    ) : (
                      <></>
                    )}
                    <div className='cont_info_iframe_anime_body_title_en'>
                        <span
                        className={`${
                            anime.score >= 8 ? 'hard_score' : anime.score <= 7 ? 'low_score' : 'high_score'
                        }`}
                        >
                            {renderScoreWithSVG(anime.score.toFixed(1))}
                        </span>
                        <span> {anime.name}</span>
                    </div>
                    <p className='anime_main_block_kind_year_genres'>
                        <span className='anime_main_block_between'> </span>
                        <span>c {anime.airedOn ? anime.airedOn.year : 'Не указано'}
                        </span>
                        <span className='anime_main_block_between'>, </span>
                        <span>
                        {anime.genres.filter((genre) => genre.kind === 'genre' || genre.kind === 'demographic')
                            .map((genre) => genre.russian)
                            .join(', ')}
                        </span>
                        <span className='anime_main_block_between'>, </span>
                        <span>{anime.episodesAired} эп</span>
                        <span className='anime_main_block_between'>, </span>
                        <span>{kindTranslations[anime.kind] || anime.kind}</span>
                        <span className='anime_main_block_between'>, </span>
                        <span>{statusTranslations[anime.status] || anime.status}</span>
                    </p>
                    <div className='auxiliary_butt'>
                        {isHentai || anime.status === 'anons' ? (
                        <div className='auxiliary_butt_watch disabled' style={{cursor: 'not-allowed'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none">
                            <g clipPath="url(#clip0_429_11057)">
                                <path d="M5.63604 5.63598C4.00736 7.26466 3 9.51466 3 11.9999C3 16.9705 7.02944 20.9999 12 20.9999C14.4853 20.9999 16.7353 19.9926 18.364 18.3639M5.63604 5.63598C7.26472 4.0073 9.51472 2.99994 12 2.99994C16.9706 2.99994 21 7.02938 21 11.9999C21 14.4852 19.9926 16.7352 18.364 18.3639M5.63604 5.63598L18.364 18.3639" stroke="#292929" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_429_11057">
                                <rect width="24" height="24" fill="white"/>
                                </clipPath>
                            </defs>
                            </svg>
                            Недоступно
                        </div>
                        ) : (
                        <a href={`/play/${anime.id}`}>
                            <div className='auxiliary_butt_watch'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="-0.5 0 8 8" version="1.1">
                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <g id="Dribbble-Light-Preview" transform="translate(-427.000000, -3765.000000)" fill="#000000">
                                            <g id="icons" transform="translate(56.000000, 160.000000)">
                                                <polygon id="play-[#1001]" points="371 3605 371 3613 378 3609"></polygon>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                                {isFetchedFromServer ? 'Смотреть в 4К' : 'Смотреть с 1 эп'}
                            </div>
                        </a>
                        )}
                        {showVideo && ( // Кнопка звука появляется только когда видео отображается
                            <div
                            className="iframe_anime_body_butt_sound"
                            onClick={handleSoundToggle}
                            >
                            {isMuted ? (
                                <svg width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className="PlayerControls_volumeIcon__J2RHB" aria-label="Включить звук">
                                <path fillRule="evenodd" clipRule="evenodd" d="M6 8.5 12.5 4v16L6 15.5H2.5v-7H6Zm10.393 7.493 2.296-2.296 2.296 2.296 1.698-1.697L20.386 12l2.297-2.296-1.698-1.697-2.296 2.296-2.296-2.296-1.697 1.697L16.992 12l-2.296 2.296 1.697 1.697Z" fill="#fff"></path>
                                </svg>
                            ) : (
                                <svg width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className="PlayerControls_volumeIcon__J2RHB" aria-label="Выключить звук">
                                <path fillRule="evenodd" clipRule="evenodd" d="M24 12a11 11 0 0 1-4.219 8.672l-1.583-1.81A8.605 8.605 0 0 0 21.6 12c0-2.8-1.335-5.287-3.402-6.861l1.583-1.81A11 11 0 0 1 24 12Zm-4.722 0a6.288 6.288 0 0 1-2.614 5.108l-1.592-1.819a3.893 3.893 0 0 0 1.806-3.288c0-1.383-.72-2.597-1.806-3.289l1.592-1.82a6.288 6.288 0 0 1 2.614 5.109ZM6 8.5 12.5 4v16L6 15.5H2.5v-7H6Z" fill="#fff"></path>
                                </svg>
                            )}
                            </div>
                        )}
                    </div>

                    <div className='auxiliary_butt_dopp'>
                        <div className='auxiliary_butt_favourites' onClick={handleAddToRemembered}>
                          <div className='body_auxiliary_butt_favourites' style={{ backgroundColor: isRemembered ? "#EB6262" : "#252525" }}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill='#ffff' xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.1333 3.66666V18.3333L11.1239 15.849L17.1145 18.3333V3.66666H5.1333ZM13.0942 12.8384L11.1239 11.5808L9.1534 12.8384L9.74064 10.5757L7.93575 9.0902L10.269 8.94938L11.1239 6.77393L11.9787 8.94938L14.3121 9.0902L12.5072 10.5757L13.0942 12.8384Z"/>
                            </svg>
                          </div>
                            <p>Запомнить</p>
                        </div>

                        <div className='auxiliary_butt_favourites' onClick={handleAddToViewed}>
                          <div className='body_auxiliary_butt_favourites' style={{ backgroundColor: isViewed ? "#EB6262" : "#252525" }}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill='#ffff' xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9999 3.66666C17.4166 3.66666 20.1666 11 20.1666 11C20.1666 11 17.4166 18.3333 10.9999 18.3333C4.58325 18.3333 1.83325 11 1.83325 11C1.83325 11 4.58325 3.66666 10.9999 3.66666ZM10.9999 7.79166C9.22802 7.79166 7.79158 9.22809 7.79158 11C7.79158 12.7719 9.22802 14.2083 10.9999 14.2083C12.7718 14.2083 14.2083 12.7719 14.2083 11C14.2083 9.22809 12.7718 7.79166 10.9999 7.79166ZM10.9999 12.375C10.2418 12.375 9.62492 11.7582 9.62492 11C9.62492 10.2418 10.2418 9.62499 10.9999 9.62499C11.7581 9.62499 12.3749 10.2418 12.3749 11C12.3749 11.7582 11.7581 12.375 10.9999 12.375Z"/>
                            </svg>
                          </div>
                            <p>Просмотренно</p>
                        </div>

                        <div className='auxiliary_butt_favourites'>
                            <div className='body_auxiliary_butt_favourites'>
                              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <g clip-path="url(#clip0_206_220)">
                                      <path d="M10.8276 13.5882H9.10216C7.68691 13.5877 6.29843 13.974 5.08683 14.7054C3.87523 15.4368 2.8866 16.4855 2.2278 17.738C2.20929 17.5056 2.20008 17.2724 2.2002 17.0392C2.2002 12.2743 6.06271 8.41176 10.8276 8.41176V3.66666L19.8865 11L10.8276 18.3333V13.5882ZM9.10216 11.8627H12.5531V14.7167L17.1438 11L12.5531 7.28328V10.1372H10.8276C9.83571 10.1361 8.85523 10.3493 7.95332 10.7622C7.0514 11.1751 6.24933 11.778 5.602 12.5296C6.71608 12.0881 7.90377 11.8618 9.10216 11.8627Z" fill="#ffff"/>
                                  </g>
                                  <defs>
                                      <clipPath id="clip0_206_220">
                                          <rect width="22" height="22" fill="white"/>
                                      </clipPath>
                                  </defs>
                              </svg>
                            </div>
                            <p>Поделиться</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='anime_details_info_cont'>
              {anime.status === 'ongoing' && (
                <div className='anime_new_episode_block'>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                    Следующая серия выйдет
                    </div>
                    <div>{formatDate(anime.nextEpisodeAt)}</div> {/* Форматирование даты */}
                </div>
                )}
                {anime.status === 'anons' && (
                <div className='anime_date_release_block'>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                    Дата выпуска
                    </div>
                    <div>{anime.airedOn.date ? formatDate(anime.airedOn.date) : 'Неизвестно'}</div>
                </div>
                )}
                {anime.genres.some((genre) => genre.name === 'Hentai') && (
                <div className='anime_date_release_block'>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 24 24" fill="none" style={{marginRight: '5px'}}>
                        <path d="M12 7V13M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#ffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <circle cx="12" cy="16.5" r="1" fill="#ffff"/>
                    </svg>
                    Доступ ограничен в связи с обращением правообладателя.
                    </div>
                    <div></div>
                </div>
                )}
                <div className='anime_details_info_cont_description'>
                  {cleanDescription(anime.description)}
                </div>
                <div className='anime_details_info_cont_dopp' onClick={toggleDetails}>
                    Подробнее о {kindTranslationsInfo[anime.kind] || anime.kind}
                </div>
            </div>

            <div className='anime_episode_block_all'>
                <div className='anime_episode_block_all_left'>
                    <span>Все серии</span>
                    <p>{anime.episodesAired} эпизодов</p>
                </div>
                <div className='anime_episode_block_all_right' onClick={toggleEpisodeVisibility}>
                    Все
                </div>
            </div>

            <div className='anime_range'>
                <span>Рейтинг</span>
                <div className='anime_range_block'>
                    <span
                        className={`anime_range_all ${
                            anime.score >= 8 ? 'hard_score' : anime.score <= 7 ? 'low_score' : 'high_score'
                        }`}
                    >
                        {renderScoreWithSVG(anime.score.toFixed(1))}
                    </span>
                    <span>
                      {materialData && materialData.imdb_votes 
                      ? `${materialData.imdb_votes} оценок` 
                      : 'Нет оценок'}
                    </span>
                </div>
            </div>
            
            <div className='anime_center_comments_block'>
                <div className='anime_center_comments_block_title'>Комментарии</div>
                <form onSubmit={handleCommentSubmit} className='profile_comments_anime'>
                  <textarea
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    placeholder="Напишите ваш комментарий..."
                    required
                  />
                  <button type="submit">Оставить комментарий</button>
                </form>
                <div className='anime_center_comments_block_list'>
                  {comments.map((comment) => (
                    <div className='anime_center_comments_users_comm' key={comment.id}>
                      <div className='anime_center_comments_user_info'>
                        <div style={{ backgroundImage: `url(${comment.avatar})` }}></div>
                        <span>{comment.login}</span>
                      </div>
                      <div className='anime_center_comments_user_text'>
                        <p>{comment.comment}</p>
                      </div>
                    </div>
                  ))}
                </div>
            </div>

            {isEpisodeVisible && (
              <div className='page_episode_anime visible'>
                <div className='anime_butt_back' onClick={closeEpisode}>
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.9916 16.3109L14.3193 14.9832L10.3361 11L14.3193 7.0168L12.9916 5.68906L7.68066 11L12.9916 16.3109Z" fill="white"/>
                  </svg>
                  Вернуться к аниме
                </div>
                <div className='block_catalog_episode_item'>
                {episodes.length > 0 ? episodes.map((episode, index) => {
                  const episodeScreenshots = episode.screenshots || [];
                  return (
                    <div className='catalog_episode_item' key={episode.id}>
                      <a onClick={() => handleEpisodeClick(index)}>
                        <div className='item_episode'>
                          {episodeScreenshots.length > 0 ? (
                            <img src={episodeScreenshots[0]} alt={`Скриншот эпизода ${index + 1}`} />
                          ) : (
                            <img src="../icons/item.jpg" alt="Эпизод" />
                          )}
                          <div className='info_item_episode'>
                            <div className='info_item_episode_main'>
                              <div className='episode_item_name_circle'></div>
                              {episode.title ? episode.title : `${index + 1}`}
                            </div>
                            <div className='info_item_episode_desc'>Нет описание к серии</div>
                          </div>
                        </div>
                      </a>
                    </div>
                  );
                }) : (
                  <p>Эпизоды не найдены.</p>
                )}
                </div>
              </div>
            )}
            
          </div>
        )}
    </>
  )
}
