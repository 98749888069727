import React, { Component } from 'react'
import ProfileUserInfo from '../components/profile'

export default class profile extends Component {
  render() {
    return (
      <>
        <ProfileUserInfo />
      </>
    )
  }
}
