import React from 'react';
import { useQuery, gql } from '@apollo/client';

// GraphQL запрос для получения аниме
const GET_ANIME_ANNOUNCEMENTS = gql`
  query GetAnimeAnnouncements {
    animes(limit: 5, kind: "tv", order: popularity, season: "winter_2025") {
      id
      name
      russian
      score
      episodesAired
      poster {
        originalUrl
      }
      genres {
        russian
        kind
      }
      description
    }
  }
`;

const svgIcons = [
  <svg width="116" height="81" viewBox="0 0 116 81" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_187_324)">
      <path d="M43.2364 15.9063L39.7915 0H86.0509L68.5806 66.5619H110.657V81H5.34302L11.2485 66.5619H51.1103L65.3818 15.9063H43.2364Z" fill="url(#paint0_linear_187_324)" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_187_324" x1="58" y1="0" x2="58" y2="81" gradientUnits="userSpaceOnUse">
        <stop stopColor="#CBC7C9" />
        <stop offset="0.395" stopColor="#C2B3C5" />
        <stop offset="0.505" stopColor="#C18DF6" />
        <stop offset="0.6" stopColor="#79B0F0" />
        <stop offset="1" stopColor="#999999" />
      </linearGradient>
      <clipPath id="clip0_187_324">
        <rect width="116" height="81" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  <svg width="116" height="81" viewBox="0 0 116 81" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_187_327)">
      <path d="M8.56453 27.6387H27.2353C27.2353 -2.88666 170.878 16.4539 12.7982 64.4559L5.3299 81H110.384V64.4559H61.3407C186.312 -2.18762 5.32923 -21.7612 8.56453 27.6387Z" fill="url(#paint0_linear_187_327)" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_187_327" x1="57.8572" y1="0" x2="57.8572" y2="81" gradientUnits="userSpaceOnUse">
        <stop stopColor="#CBC7C9" />
        <stop offset="0.395" stopColor="#C2B3C5" />
        <stop offset="0.505" stopColor="#C18DF6" />
        <stop offset="0.6" stopColor="#79B0F0" />
        <stop offset="1" stopColor="#999999" />
      </linearGradient>
      <clipPath id="clip0_187_327">
        <rect width="115.714" height="81" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  <svg width="115" height="81" viewBox="0 0 115 81" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_238_248)">
        <path d="M11.9311 12.9851L15.8624 0H105.545L90.0659 20.2749C155.915 57.4079 37.7304 122.333 5.297 44.1948H19.3023C60.8269 116.866 157.144 5.23957 39.9417 44.1948L35.7647 31.6654L65.9867 12.9851H11.9311Z" fill="url(#paint0_linear_238_248)"/>
    </g>
    <defs>
        <linearGradient id="paint0_linear_238_248" x1="57.5" y1="0" x2="57.5" y2="81" gradientUnits="userSpaceOnUse">
            <stop stop-color="#CBC7C9"/>
            <stop offset="0.395" stop-color="#C2B3C5"/>
            <stop offset="0.505" stop-color="#C18DF6"/>
            <stop offset="0.6" stop-color="#79B0F0"/>
            <stop offset="1" stop-color="#999999"/>
        </linearGradient>
        <clipPath id="clip0_238_248">
            <rect width="115" height="81" fill="white"/>
        </clipPath>
    </defs>
  </svg>,
  <svg width="116" height="81" viewBox="0 0 116 81" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_239_251)">
        <path d="M15.9668 0H31.9025L24.9739 35.9421H74.4354L82.0189 0H97.9548L90.8835 35.9421H110.657L107.885 51.3088H87.8604L82.0189 81H64.9286L71.1933 51.3088H5.34302L15.9668 0Z" fill="url(#paint0_linear_239_251)"/>
    </g>
    <defs>
        <linearGradient id="paint0_linear_239_251" x1="58" y1="0" x2="58" y2="81" gradientUnits="userSpaceOnUse">
            <stop stop-color="#CBC7C9"/>
            <stop offset="0.395" stop-color="#C2B3C5"/>
            <stop offset="0.505" stop-color="#C18DF6"/>
            <stop offset="0.6" stop-color="#79B0F0"/>
            <stop offset="1" stop-color="#999999"/>
        </linearGradient>
        <clipPath id="clip0_239_251">
            <rect width="116" height="81" fill="white"/>
        </clipPath>
    </defs>
  </svg>,
  <svg width="116" height="81" viewBox="0 0 116 81" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_239_254)">
        <path d="M65.3345 0H21.1251L13.4937 41.4683C165.068 13.4168 68.8033 103.325 23.2303 51.4306H5.33606C74.1541 138.792 190.006 2.34842 29.3811 26.772L33.8418 10.8027H62.2622L65.3345 0Z" fill="url(#paint0_linear_239_254)"/>
    </g>
    <defs>
        <linearGradient id="paint0_linear_239_254" x1="57.9245" y1="0" x2="57.9245" y2="81.0001" gradientUnits="userSpaceOnUse">
            <stop stop-color="#CBC7C9"/>
            <stop offset="0.395" stop-color="#C2B3C5"/>
            <stop offset="0.505" stop-color="#C18DF6"/>
            <stop offset="0.6" stop-color="#79B0F0"/>
            <stop offset="1" stop-color="#999999"/>
        </linearGradient>
        <clipPath id="clip0_239_254">
            <rect width="115.849" height="81" fill="white"/>
        </clipPath>
    </defs>
  </svg>    
];

export default function Compilation() {
  const { loading, error, data } = useQuery(GET_ANIME_ANNOUNCEMENTS);

  if (loading) return (
    <div className="list">
      <div className="header_list">
        <span>
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.3088 13.6613L13.1097 11.0505L17.7626 0L5.69116 11.3376L11.8905 13.9485L7.23733 25L19.3088 13.6613Z" fill="url(#paint0_linear_187_308)"/>
                <defs>
                    <linearGradient id="paint0_linear_187_308" x1="12.5" y1="0" x2="12.5" y2="25" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#CBC7C9"/>
                        <stop offset="0.395" stop-color="#C2B3C5"/>
                        <stop offset="0.505" stop-color="#C18DF6"/>
                        <stop offset="0.6" stop-color="#79B0F0"/>
                        <stop offset="1" stop-color="#999999"/>
                    </linearGradient>
                </defs>
            </svg>
            Топ-5 за месяц
        </span>
      </div>
      <div className="list_catalog">
        {Array.from({ length: 5 }, (_, index) => (
          <div className="cart_compilation loading_cart_compilation">
            <div className="cart_icon">
              {svgIcons[index % svgIcons.length]}
            </div>
            <div className='item_compilation'>
              <div className='cart_image'><div className='loading-bar'></div></div>
              <div className="cart_info">
                  <p className="title_cart_info"><div className='loading-bar'></div></p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
  if (error) return <p>Ошибка: {error.message}</p>;

  return (
    <div className="list">
      <div className="header_list">
        <span>
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.3088 13.6613L13.1097 11.0505L17.7626 0L5.69116 11.3376L11.8905 13.9485L7.23733 25L19.3088 13.6613Z" fill="url(#paint0_linear_187_308)"/>
                <defs>
                    <linearGradient id="paint0_linear_187_308" x1="12.5" y1="0" x2="12.5" y2="25" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#CBC7C9"/>
                        <stop offset="0.395" stop-color="#C2B3C5"/>
                        <stop offset="0.505" stop-color="#C18DF6"/>
                        <stop offset="0.6" stop-color="#79B0F0"/>
                        <stop offset="1" stop-color="#999999"/>
                    </linearGradient>
                </defs>
            </svg>
            Топ-5 за месяц
        </span>
      </div>
      <div className="list_catalog">
        {data.animes.map((anime, index) => (
          <a href={`/anime/${anime.id}`} key={anime.id}>
            <div className="cart_compilation">
              <div className="cart_icon">
                {svgIcons[index % svgIcons.length]} {/* Связываем иконку с элементом */}
              </div>
              <div className='item_compilation'>
                <img src={anime.poster.originalUrl} alt={anime.russian} />
                <div className="cart_info">
                    <p className="title_cart_info">{anime.russian}</p>
                </div>
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
}
