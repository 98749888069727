import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import client from './components/apolloClient';
import Page from './page/page';
import Auth from './page/auth';
import Anime from './page/anime';
import Play from './page/play';

function App() {
  return (
    <ApolloProvider client={client}>
      <div className="m.aniplay.fun">
        <Routes>
          <Route path="/app" element={<Page />} />
          <Route path="/auth" element={<Auth />} />
          <Route path="/anime/:id" element={<Anime />} />
          <Route path="/play/:id" element={<Play />} />
          <Route path="*" element={<Navigate to="/app" replace />} />
        </Routes>
      </div>
    </ApolloProvider>
  );
}

export default App;
