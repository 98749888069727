import React, { Component } from 'react'
import Banner from '../components/banner'
import Popular from '../components/popular'
import Ongoing from '../components/ongoing'
import Anons from '../components/anons'
import Lates from '../components/lates'
import Top2024 from '../components/top2024'
import Movie from '../components/movie'
import Compilation from '../components/compilation'
import Footer from '../components/footer'
import Anime from '../components/anime'

export default class welcome extends Component {
  render() {
    return (
      <>
        <Banner />
        <Popular />
        <Ongoing />
        <Anons />
        <Compilation />
        <Lates />
        <Top2024 />
        <Movie />
        <Footer />
        <div style={{height: '71px'}}></div>
      </>
    )
  }
}
