import React, { Component } from 'react'
import Player from '../components/play'

export default class play extends Component {
  render() {
    return (
      <Player />
    )
  }
}
