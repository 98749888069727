import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const ProfileUserInfo = () => {
  const [profileData, setProfileData] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [bannerMessage, setBannerMessage] = useState(null);  // Состояние для баннера
  const [isEditing, setIsEditing] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const [banner, setBanner] = useState(null);  // Состояние для баннера
  const [description, setDescription] = useState('');  // Состояние для описания
  const [loading, setLoading] = useState(false);
  const avatarInputRef = useRef(null);
  const bannerInputRef = useRef(null);
  const navigate = useNavigate();

  // Скрытие сообщений через 5 секунд
  useEffect(() => {
    if (errorMessage || successMessage || bannerMessage) {
      const timer = setTimeout(() => {
        setErrorMessage(null);
        setSuccessMessage(null);
        setBannerMessage(null);  // Очистить сообщение баннера
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage, successMessage, bannerMessage]);

  // Получение данных профиля при загрузке страницы
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await fetch('https://api.aniplay.fun/api/user/profile', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.status === 401) {
          localStorage.removeItem('token');
          navigate('/auth');
          return;
        }

        if (!response.ok) {
          throw new Error('Ошибка при получении данных пользователя');
        }

        const data = await response.json();
        setProfileData(data);
        setAvatar(data.avatar);
        setBanner(data.banner);  // Получить URL баннера
        setDescription(data.description);  // Установить описание
      } catch (err) {
        setErrorMessage(err.message);
        localStorage.removeItem('token');
        navigate('/auth');
      }
    };

    fetchProfile();
  }, [navigate]);

  // Логика выхода из аккаунта
  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/auth');
  };

  // Переключение режима редактирования
  const toggleEditPage = () => {
    setIsEditing(!isEditing);
  };

  const closeEditPage = () => {
    setIsEditing(false);
  };

  // Обработчик для загрузки файлов
  const handleFileChange = (event, type) => {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
      if (!allowedTypes.includes(file.type)) {
        setErrorMessage('Неверный формат файла. Разрешены только JPEG, PNG и GIF.');
        return;
      }
      if (type === 'avatar') {
        uploadFile(file, 'avatar');
      } else if (type === 'banner') {
        uploadFile(file, 'banner');
      }
    }
  };

  // Загрузка файла (аватар или баннер)
  const uploadFile = async (file, type) => {
    setLoading(true);
    const formData = new FormData();
    formData.append(type, file);

    try {
      const response = await fetch(`https://api.aniplay.fun/upload-${type}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Ошибка загрузки ${type}`);
      }

      const data = await response.json();
      if (type === 'avatar') {
        setAvatar(data.avatarUrl);
        setSuccessMessage('Аватар успешно обновлен!');
      } else if (type === 'banner') {
        setBanner(data.bannerUrl);  // Сохранить URL баннера
        setBannerMessage('Баннер успешно обновлен!');  // Показать сообщение для баннера
      }
    } catch (err) {
      setErrorMessage(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Сохранение только описания
  const saveDescription = async () => {
    try {
      setLoading(true);
  
      // Подготовим данные для отправки
      const dataToSend = {
        description: description || profileData.description,  // Если описание пустое, отправляем текущие данные
        avatar: avatar || profileData.avatar,                  // Если аватар пустой, отправляем текущие данные
        banner: banner || profileData.banner,                  // Если баннер пустой, отправляем текущие данные
      };
  
      // Логирование данных перед отправкой
      console.log('Saving description:', dataToSend);
  
      const response = await fetch('https://api.aniplay.fun/api/save-settings', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(dataToSend), // Отправляем все поля
      });
  
      // Получаем ответ
      const responseData = await response.json();
  
      // Логирование ответа сервера
      console.log('Response:', responseData);
  
      if (!response.ok) {
        throw new Error(responseData.error || 'Неизвестная ошибка');
      }
  
      setSuccessMessage('Описание успешно обновлено!');
    } catch (err) {
      console.error('Error:', err);
      setErrorMessage(err.message);
    } finally {
      setLoading(false);
    }
  };
  
  

  // Если профиль еще не загружен
  if (!profileData) {
    return <div>Загрузка...</div>;
  }

  return (
    <>
      <div className={`profile_main_user_info ${isEditing ? 'hidden' : ''}`}>
        <div className="profile_user_main">
          <div className="status_profile_user">Онлайн</div>
          <div className="profile_user_banner">
            <img className="user_avatar_banner" src={banner} alt="User banner" />
          </div>
          <div className="profile_user_main_avatar">
            <img className="user_avatar_profile" src={avatar} alt="User avatar" />
            <div className="profile_user_name">{profileData.login}</div>
          </div>
          <div className="profile_user_main_descript">{profileData.description}</div>
          <div className="profile_user_edit_butt" onClick={toggleEditPage}>
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.1667 18.3333V20.1667H1.83337V18.3333H20.1667ZM9.82638 6.21531L13.9514 10.3403L7.79171 16.5H3.66671V12.375L9.82638 6.21531ZM13.75 2.29166L17.875 6.41666L14.9237 9.36801L10.7987 5.24301L13.75 2.29166Z" fill="black"/>
            </svg>
            Редактировать
          </div>
          <div className='profile_user_process'>
            <p className='profile_user_process_el'>
              Запомненых
              <div>0</div>
            </p>
            <p className='profile_user_process_el'>
              Просмотренных
              <div>0</div>
            </p>
          </div>
          <div className="butt_exit_user" onClick={handleLogout}>
            Сменить аккаунт
          </div>
        </div>
      </div>

      <div className={`edit_profile_user_page ${isEditing ? 'visible' : ''}`}>
        <span>Редактирование профиля</span>
        <div className="edit_avatar_user">
          <input
            type="file"
            ref={avatarInputRef}
            style={{ display: 'none' }}
            onChange={(e) => handleFileChange(e, 'avatar')}
          />
          <button onClick={() => avatarInputRef.current.click()} disabled={loading}>
            {loading ? 'Загрузка...' : 'Выберите новую аватарку'}
          </button>
        </div>

        <div className="edit_banner_user">
          <input
            type="file"
            ref={bannerInputRef}
            style={{ display: 'none' }}
            onChange={(e) => handleFileChange(e, 'banner')}
          />
          <button onClick={() => bannerInputRef.current.click()} disabled={loading}>
            {loading ? 'Загрузка...' : 'Выберите новый баннер'}
          </button>
        </div>

        <div className="ebit_description_user">
          <input
            type="text"
            placeholder="Введите новое описание"
            value={description}
            onChange={(e) => setDescription(e.target.value)}  // Обновляем состояние при изменении
          />
        </div>

        <div className="edit_profile_user_page_butt">
          <div className="edit_profile_user_page_butt_back" onClick={closeEditPage}>
            Назад
          </div>
          <div className="edit_profile_user_page_butt_save">
            <button onClick={saveDescription} disabled={loading}>
              {loading ? 'Сохранение...' : 'Сохранить'}
            </button>
          </div>
        </div>
      </div>

      {errorMessage || successMessage || bannerMessage ? (
        <div className={`alert ${errorMessage ? 'error' : 'success'}`}>
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.9999 1.83333C16.0618 1.83333 20.1666 5.93817 20.1666 11C20.1666 16.0618 16.0618 20.1667 10.9999 20.1667C5.93809 20.1667 1.83325 16.0618 1.83325 11C1.83325 5.93817 5.93809 1.83333 10.9999 1.83333ZM11.9166 6.41667H10.0833V12.2964L13.1017 15.3148L14.3981 14.0185L11.9166 11.5363V6.41667Z"
              fill="white"
            />
          </svg>
          <span className="alert_message success">
            {errorMessage || successMessage || bannerMessage}
          </span>
        </div>
      ) : null}
    </>
  );
};

export default ProfileUserInfo;
