import React, { Component } from 'react'
import Collection from '../components/collection'

export default class collection extends Component {
  render() {
    return (
      <>
        <Collection />
      </>
    )
  }
}
